import React from "react"
import "./style.scss"

class CancelAuto extends React.Component {
  render() {
    return (
      <div className="cancel-button">
        <button onClick={this.props.onCancel}>Disable Auto Deposit</button>
      </div>
    )
  }
}
export default CancelAuto
