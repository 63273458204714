import React from "react"
import SEO from "@components/seo"
import * as data from "@data/pledge"
import Layout from "@components/layout"
import SetAutoDeposit from "@components/_molecules/auto_deposit_set"

const AutoDepositPage = () => {
  const { autoDeposit } = data
  return (
    <Layout type="app">
      <SEO title={autoDeposit.title} />
      <SetAutoDeposit />
    </Layout>
  )
}

export default AutoDepositPage
