import React, { Component } from "react"
import "./style.scss"
import axios from "axios"
import * as headers from "@data/headers"
import BackPage from "@components/_molecules/back_parent_dashboard"
import FormButton from "@components/_atoms/button_form_input_submit"
import GeneralSubmitMessage from "@components/_atoms/general_submit_message"
import * as data from "@data/parentDash.js"
import { format } from "path"
import * as errors from "@services/errors"
import { url } from "inspector"
import CancelAuto from "@components/_atoms/button_cancel_auto"
import ResponseMessage from "@components/_atoms/response_message"
import BankStatusPending from "@components/_atoms/pending_bank_message"
import { isNull } from "util"
import ConfirmModal from "@components/_molecules/modal_confirm"

class SetAutoDeposit extends Component {
  constructor(props) {
    super(props)
    this.state = {
      recurringDepositId: "",
      recurringDepositAmount: "",
      recurringDepositInterval: "",
      nextRecurringDeposit: "",
      paymentAccountId: "",
      buttonSubmitted: true,
      generalError: "",
      bankStatus: null,
      zeroAsk: false,
      disableAsk: false,
      success: null
    }
    this.handleChange = this.handleChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleConfirm = this.handleConfirm.bind(this)
    this.handleCancel = this.handleCancel.bind(this)
    this.handleOnClick = this.handleOnClick.bind(this)
    this.cancelAutoDeposit = this.cancelAutoDeposit.bind(this)
  }

  handleChange = event => {
    this.setState({
      [event.target.name]: event.target.value,
      [`${event.target.name}ErrorStatus`]: false,
      generalError: null
    })
  }
  handleConfirm() {
    let _this = this
    this.setState({
      zeroAsk: false
    })
    let deletePaymentAccount = {
      query: `mutation($deletePaymentAccountInput: DeletePaymentAccountInput!) {
    deletePaymentAccount (input: $deletePaymentAccountInput) {
      paymentAccount {
        id
      }
    }
  }`,
      variables: {
        deletePaymentAccountInput: {
          id: _this.state.paymentAccountId
        }
      }
    }
    const deleteOptions = {
      method: "POST",
      data: JSON.stringify(deletePaymentAccount),
      headers: headers.getHeaders(),
      url: headers.api
    }
    axios(deleteOptions)
      .then(res => {
        _this.setState({ buttonSubmitted: false })
        if (res.data.errors) {
          _this.errorHandler(res.data.errors[0].message)
        } else {
          _this.setState({
            success: "success"
          })
          location.reload()
        }
      })
      .catch(error => {
        _this.errorHandler(error)
      })
  }
  handleCancel() {
    this.setState({ buttonSubmitted: false, zeroAsk: false, disableAsk: false })
  }
  handleSubmit = event => {
    event.preventDefault()
    let _this = this //needed to avoid losing context during axios/fetch call
    this.setState({ buttonSubmitted: true })
    if (this.state.recurringDepositAmount === "") {
      this.setState({ buttonSubmitted: false })
    } else {
      if (this.state.nextRecurringDeposit) {
        if (this.state.recurringDepositAmount === "0") {
          this.setState({
            zeroAsk: true
          })
        }
      } else {
        let createAutoMutation = {
          query: `mutation($createUserRecurringDepositInput: CreateUserRecurringDepositInput!) {
          createUserRecurringDeposit(input: $createUserRecurringDepositInput) {
            userRecurringDeposit {
              amount
              paymentAccountId
              paymentInterval
              userId
            }
          }
        }`,

          variables: {
            createUserRecurringDepositInput: {
              recurringDeposit: {
                amount: _this.state.recurringDepositAmount,
                paymentInterval: "monthly",
                paymentAccountId: _this.state.paymentAccountId
              }
            }
          }
        }
        const createOptions = {
          method: "POST",
          data: JSON.stringify(createAutoMutation),
          headers: headers.getHeaders(),
          url: headers.api
        }
        axios(createOptions)
          .then(res => {
            _this.setState({ buttonSubmitted: false })
            if (res.data.errors) {
              _this.errorHandler(res.data.errors[0].message)
            } else {
              _this.setState({
                success: "success"
              })
              location.reload()
            }
          })
          .catch(error => {
            console.log(error)
            _this.errorHandler(error)
          })
      }
    }
  }

  componentDidMount = () => {
    let _this = this
    let recurringDepositsQuery = {
      query: `query {
          user {
            recurringDeposits {
              nodes {
                id
                amount
                scheduledAt
                paymentInterval
                deletedAt
              }
            }
            paymentAccounts(linked: true) {
              id
              status
              displayAccountNumber
            }
          }
        }
        `
    }
    const options = {
      method: "POST",
      data: JSON.stringify(recurringDepositsQuery),
      headers: headers.getHeaders(),
      url: headers.api
    }

    var recurringDeposit

    axios(options)
      .then(res => {
        if (res.data.errors) {
          _this.errorHandler(res.data.errors[0].message)
        } else {
          _this.setState({
            buttonSubmitted: false,
            paymentAccountId: res.data.data.user.paymentAccounts[0].id,
            bankStatus: res.data.data.user.paymentAccounts[0].status
          })
          const user = res.data.data.user

          if (user.recurringDeposits.nodes.length) {
            recurringDeposit = user.recurringDeposits.nodes[0]
            _this.setState({
              recurringDepositId: recurringDeposit.id,
              recurringDepositAmount: recurringDeposit.amount,
              nextRecurringDeposit: new Date(recurringDeposit.scheduledAt),
              recurringDepositInterval:
                recurringDeposit.paymentInterval == "monthly"
                  ? "/month"
                  : recurringDeposit.paymentInterval
            })
          }

          if (_this.props.handleStateChange != null) {
            _this.props.handleStateChange(_this.state)
          }
        }
      })
      .catch(error => {
        console.log(error)
      })
  }
  handleOnClick() {
    this.setState({ disableAsk: true })
  }
  cancelAutoDeposit() {
    let _this = this
    this.setState({ disableAsk: false })
    let cancelAuto = {
      query: `mutation ($deleteUserRecurringDepositInput: DeleteUserRecurringDepositInput! ){
        deleteUserRecurringDeposit(input: $deleteUserRecurringDepositInput ){
          userRecurringDeposit {
            id
            deletedAt
          }
        }
      }`,
      variables: {
        deleteUserRecurringDepositInput: {
          id: _this.state.recurringDepositId
        }
      }
    }
    const options = {
      method: "POST",
      data: JSON.stringify(cancelAuto),
      headers: headers.getHeaders(),
      url: headers.api
    }
    axios(options)
      .then(res => {
        if (res.data.errors) {
          _this.errorHandler(res.data.errors[0].message)
        } else {
          _this.setState({ success: "success" })
          location.reload()
        }
      })
      .catch(rror => {
        console.log(error)
      })
  }

  errorHandler = err => {
    this.setState({
      generalError: errors.messaging(err)
    })
  }

  formatDate = date => {
    var options = {
      month: "short",
      day: "numeric"
    }

    return new Date(date).toLocaleDateString("en-US", options)
  }
  checkBankStatus = () => {
    if (this.state.bankStatus === 4) {
      return "Pending"
    } else {
      {
        return this.state.nextRecurringDeposit
          ? this.formatDate(this.state.nextRecurringDeposit)
          : "Inactive"
      }
    }
  }

  render() {
    const { autoDeposit } = data

    return (
      <section className="standard">
        <div className="heading-size">
          <BackPage data={autoDeposit.heading} />
          {this.state.zeroAsk && (
            <ConfirmModal
              data={autoDeposit.zeroDollarModal}
              onConfirm={this.handleConfirm}
              onCancel={this.handleCancel}
            />
          )}
          {this.state.disableAsk && (
            <ConfirmModal
              data={autoDeposit.disableModal}
              onConfirm={this.cancelAutoDeposit}
              onCancel={this.handleCancel}
            />
          )}
        </div>
        {this.state.bankStatus === 4 ? <BankStatusPending /> : ""}

        <div className="set-auto-deposit container-app">
          <form onSubmit={this.handleSubmit}>
            <div className="underline">
              <label>AUTO-DEPOSIT</label>
              <div className="input-date">
                <div>
                  <span className="usd">$</span>
                  <input
                    name="recurringDepositAmount"
                    type="number"
                    value={this.state.recurringDepositAmount}
                    onChange={this.handleChange}
                  />
                  <span className="interval">
                    {this.state.recurringDepositInterval}
                  </span>
                </div>
                <p
                  className={
                    this.state.nextRecurringDeposit ? "date" : "inactive"
                  }
                >
                  {this.checkBankStatus()}
                </p>
              </div>
            </div>
            <div className="copy">
              <p className="support-text">
                This will reoccur on the same day of every month
              </p>
              <p className="description">
                This amount is deposited monthly into your child’s account. The
                amount is evenly allocated to Savings and Investing. Your
                contribution must be a minimum of $10 per month.
              </p>
            </div>
            <GeneralSubmitMessage error={this.state.generalError} />
            <FormButton
              text={this.state.nextRecurringDeposit ? "Update" : "Activate"}
              isDisabled={this.state.buttonSubmitted}
            />
          </form>

          {this.state.nextRecurringDeposit && (
            <CancelAuto onCancel={this.handleOnClick} />
          )}
        </div>
      </section>
    )
  }
}
export default SetAutoDeposit
