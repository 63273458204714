import React from "react"
import "./style.scss"

const BankStatusPending = () => {
  return (
    <div className="bank-status">
      <p className="status-title">Pending Bank Approval</p>
      <p className="status-copy">
        Your Auto-Deposit is currently on hold until we finish verifying your
        bank. This usually takes 1-3 business days of when you completed bank
        registration. We’ll alert you soon for any update.
      </p>
    </div>
  )
}
export default BankStatusPending
